import React from 'react';

const PasswordStrengthMeter = ({ password }) => {
    const calculateStrength = (password) => {
        let strength = 0;

        if (password.length > 8) strength += 1;
        if (/[A-Z]/.test(password)) strength += 1;
        if (/\d/.test(password)) strength += 1;
        if (/[^a-zA-Z\d\n]/.test(password)) strength += 1;

        return strength;
    };

    const strength = calculateStrength(password);
    const strengthLabels = ['Very Weak', 'Weak', 'Moderate', 'Strong', 'Very Strong'];
    const strengthClass = ['very-weak', 'weak', 'moderate', 'strong', 'very-strong'];

    return (
        <div className="password-strength-meter">
            <div className={`strength-bar ${strengthClass[strength]}`}></div>
            <p>{strengthLabels[strength]}</p>
        </div>
    );
}; 

export default PasswordStrengthMeter;