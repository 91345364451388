import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import './AnalysisPage.css';

export class AnalysisPage extends Component {
    static displayName = AnalysisPage.name;

    constructor(props) {
        super(props);
        this.state = {
            navigateinvreturn: false,
            wasteItemName: '',
            wasteQuantity: 0,
            wasteReason: '',
            wasteEmployee: '',
            wasteManager: '',
            wasteDate: '',
            wasteRecords: [],
            startDate: 0,
            endDate: 0,
            items: [],
            itemName: '',
            units: 0,
            amount: 0,
            costPerUnit: 0,
            totalCost: 0,
            userEmail: '',
            BusinessPlan: '',
            userID: '',
            isAuthenticated: true,
            error: null,
            //wasteChartData: [],
            //wastereasonChartData: [],
            //inventoryChartData: [],
            //showSTDChart: false,
            debounceTimeout: null,
            oldReport: 'For accurate report a month of use is need\nThank you for being appericate your data check make sure it has enough...',
            newReport: 'For accurate report a month of use is need\nThank you for being appericate your data check make sure it has enough...'
        };
    }

    async componentDidMount() {
        const token = localStorage.getItem('token');

        if (!token) {
            this.setState({ isAuthenticated: false });
            return;
        }
        
        await this.fetchUserDetails(token);
        await this.fetchInventoryItems(token);
        await this.fetchRecords(token);
    }

    fetchUserDetails = async (token) => {
        try {
            const response = await fetch('/user/userdetails', {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user details');
            }

            const user = await response.json();
            this.setState({
                userEmail: user.email,
                BusinessPlan: user.businessPlan,
                userID: user.UserId,
                isAuthenticated: true,
            });
        } catch (error) {
            console.error(error);
            this.setState({ isAuthenticated: false });
        }
    };

    
    handleDateChange = async (e) => {
        // Update state with the new value (startDate or endDate)
        this.setState({ [e.target.name]: e.target.value }, async () => {
            const { startDate, endDate } = this.state;

            // Check if both startDate and endDate are available
            if (startDate && endDate) {
                try {
                    const token = localStorage.getItem('token');
                    const query = new URLSearchParams({
                        startDate: startDate,
                        endDate: endDate
                    }).toString();

                    // Fetch from /foodwaste/snapshotrecords
                    const foodWasteResponse = await fetch(`/foodwaste/snapshotrecords?${query}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    // Fetch from /inventory/snapshotitems
                    const inventoryResponse = await fetch(`/inventory/snapshotitems?${query}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    // Handle both responses
                    if (foodWasteResponse.ok && inventoryResponse.ok) {
                        const foodWasteRecords = await foodWasteResponse.json();
                        const inventoryRecords = await inventoryResponse.json();

                        // Combine both record sets (assuming you want them together)
                        

                        // Update state with combined sorted records
                        this.setState({ wasteRecords: foodWasteRecords, items:inventoryRecords });
                    } else {
                        // Handle errors for both fetches
                        const foodWasteError = await foodWasteResponse.text();
                        const inventoryError = await inventoryResponse.text();
                        this.setState({
                            error: `Failed to fetch records: ${foodWasteError || 'Food waste error'}, ${inventoryError || 'Inventory error'}`
                        });
                    }
                } catch (error) {
                    console.error('Error:', error);
                    this.setState({ error: 'An unexpected error occurred' });
                }
            } else {
                console.error('Start Date and End Date must be provided.');
                this.setState({ error: 'Start Date and End Date must be provided.' });
            }
        });
    };
    updateDateRange = (records) => {
        const currentDate = new Date();
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

        // Get the last day of the current month
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);  

        this.setState({
            wasteRecords: records,
            startDate: firstDay.toISOString().split('T')[0],
            endDate: lastDay.toISOString().split('T')[0]
        }, () => {
            this.handleDateChange({ target: { name: 'startDate', value: this.state.startDate } });
            this.handleDateChange({ target: { name: 'endDate', value: this.state.endDate } });
            
        });
    };
    toggleChartType = () => {
        // Clear the previous timeout if it exists
        this.setState(prevState => ({ showSTDChart: !prevState.showSTDChart }));
        }

        
      

    handleinvreturn = () => {
        this.setState({ navigateinvreturn: true });
    };
    handleSalesAnalysis = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/SalesAnalysis', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const oldReportData = await response.json(); // Parse the JSON response

            // Update state with old report data
            this.setState({
                oldReport: oldReportData // Store old report data in state
            });
        } catch (error) {
            console.error('Error fetching old report:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };
    handleCostManagement = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/CostManagement', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const oldReportData = await response.json(); // Parse the JSON response

            // Update state with old report data
            this.setState({
                oldReport: oldReportData // Store old report data in state
            });
        } catch (error) {
            console.error('Error fetching old report:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };
    handleInventoryManagement = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/InventoryManagement', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const oldReportData = await response.json(); // Parse the JSON response

            // Update state with old report data
            this.setState({
                oldReport: oldReportData // Store old report data in state
            });
        } catch (error) {
            console.error('Error fetching old report:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };
    handleProfitabilityAnalysis = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/ProfitabilityAnalysis', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const oldReportData = await response.json(); // Parse the JSON response

            // Update state with old report data
            this.setState({
                oldReport: oldReportData // Store old report data in state
            });
        } catch (error) {
            console.error('Error fetching old report:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };
    handleTrendAnalysis = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/TrendAnalysis', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const oldReportData = await response.json(); // Parse the JSON response

            // Update state with old report data
            this.setState({
                oldReport: oldReportData // Store old report data in state
            });
        } catch (error) {
            console.error('Error fetching old report:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };
    handleWasteAnalysis = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/WasteAnalysis', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const oldReportData = await response.json(); // Parse the JSON response

            // Update state with old report data
            this.setState({
                oldReport: oldReportData // Store old report data in state
            });
        } catch (error) {
            console.error('Error fetching old report:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    }
    handleReasonforWaste = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/ReasonforWaste', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const oldReportData = await response.json(); // Parse the JSON response

            // Update state with old report data
            this.setState({
                oldReport: oldReportData // Store old report data in state
            });
        } catch (error) {
            console.error('Error fetching old report:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    
    }
    
    handleEmployeeandManagerAccountability = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const response = await fetch('/analysis/EmployeeandManagerAccountability', {
                method: 'GET', // Use GET method for retrieving data
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch old report'); // Handle errors
            }

            const oldReportData = await response.json(); // Parse the JSON response

            // Update state with old report data
            this.setState({
                oldReport: oldReportData // Store old report data in state
            });
        } catch (error) {
            console.error('Error fetching old report:', error); // Log any errors
            this.setState({ error: error.message }); // Update error state if needed
        }
    };
    
    handleGenerateNewReport = async () => {
        const { items, wasteRecords, userID } = this.state; // Destructure existing state

            try {
                const token = localStorage.getItem('token'); // Get token from local storage
                const response = await fetch('/analysis/generateNewReport', {
                    method: 'POST', // Use POST method
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ items, wasteRecords, userID }), // Send items and wasteRecords in the request body
                });

                if (!response.ok) {
                    throw new Error('Failed to generate new report'); // Handle errors
                }

                const newReportData = await response.json(); // Parse the JSON response

                // Update state with new report data
                this.setState({
                    newReport: newReportData // Store new report data in state
                });
            } catch (error) {
                console.error('Error generating new report:', error); // Log any errors
                this.setState({ error: error.message }); // Update error state if needed
            }
        
    
    }
    buildCharts = () => {
        const wasteData = this.state.wasteRecords.map(record => ({
            name: record.wasteItemName,
            value: record.wasteQuantity,
        }));

        const inventoryData = this.state.items.map(item => ({
            name: item.productName,
            value: item.units,
        }));

        this.setState({ wasteChartData: wasteData, inventoryChartData: inventoryData });
    };
    
    renderBasicBarChart(data) {
        if (data.length === 0) {
            return <p>No data available</p>;
        }

        return (
            <div className="analysis-chart-container">
                {data.map(item => (
                    <div key={item.name} className="analysis-chart-bar">
                        <span className="analysis-chart-bar-label">{item.name}</span>
                        <div
                            className="analysis-chart-bar-fill"
                            style={{ width: `${(item.value / 100) * 100}%` }}
                        />
                        <span className="analysis-chart-bar-value">{item.value}</span>
                    </div>
                ))}
            </div>
        );
    }

    renderSTDBarChart(data) {
        if (data.length === 0) {
            return <p>No data available</p>;
        }

        return (
            <div className="analysis-chart-container">
                {data.map(item => (
                    <div key={item.name} className="analysis-chart-bar">
                        <span className="analysis-chart-bar-label">{item.name}</span>
                        <div
                            className="analysis-chart-bar-fill"
                            style={{ width: `${(item.value / 100) * 100}%` }}
                        />
                        <span className="analysis-chart-bar-value">{item.value}</span>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        const { navigateinvreturn, /*wasteChartData, wastereasonChartData, inventoryChartData, showSTDChart,*/ oldReport, newReport } = this.state;

        if (navigateinvreturn) {
            return <Navigate to="/inventorysystem" />;
        }

        return (
            <div>
                <h1 className="analysis-title">Analysis</h1>
                <button className="analysis-button" onClick={this.handleinvreturn}>Return</button>
                <div className="analysis-form-group">
                    <label htmlFor="startDate" className="analysis-label">Start Date:</label>
                    <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        className="analysis-input-date"
                        value={this.state.startDate}
                        onChange={this.handleDateChange}
                    />
                </div>
                <div className="analysis-form-group">
                    <label htmlFor="endDate" className="analysis-label">End Date:</label>
                    <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        className="analysis-input-date"
                        value={this.state.endDate}
                        onChange={this.handleDateChange}
                    />
                </div>
                <div className="button-container">
                    <button className="analysis-button" onClick={this.handlegenerateNewReport}>Generate New Report</button>
                </div>
                <div className="button-container">
                <button className="analysis-button" onClick={this.handleSalesAnalysis}>Sales Analysis</button>
                <button className="analysis-button" onClick={this.handleCostManagement}>Cost Management</button>
                <button className="analysis-button" onClick={this.handleInventoryManagement}>Inventory Management</button>
                <button className="analysis-button" onClick={this.handleProfitabilityAnalysis}>Profitability Analysis</button>
                
                </div>
                <div className="button-container">
                    
                    <button className="analysis-button" onClick={this.handleTrendAnalysis}>Trend Analysis</button>
                    <button className="analysis-button" onClick={this.handleWasteAnalysis}>Waste Analysis</button>
                    <button className="analysis-button" onClick={this.handleReasonforWaste}>Reason for Waste</button>
                    <button className="analysis-button" onClick={this.handleEmployeeandManagerAccountability}>Employee and Manager Accountability</button>
                </div>
                <div className="report-container">
                    <div className="report old-report">
                        <h2>Old Report</h2>
                        <pre>{oldReport}</pre>
                    </div>
                    <div className="report new-report">
                        <h2>New Report</h2>
                        <pre>{newReport}</pre>
                    </div>
                </div>

               
            </div>
        );
    }
}