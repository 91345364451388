import React, { Component } from 'react';
import { Navigate } from 'react-router-dom'; // Import Navigate for redirection
import './InventorySystem.css';
export class FoodWastePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wasteItemName: '',
            wasteQuantity: 0,
            wasteReason: '',
            wasteEmployee: '',
            wasteManager: '',
            wasteDate: '',
            wasteRecords: [],
            editingRecord: null, // State to keep track of the record being edited
            navigateinvreturn: false,
            startDate: 0,
            endDate: 0,
            searchQuery: ''
        };
    }

    componentDidMount() {
        this.fetchRecords();
    }

    fetchRecords = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('/foodwaste/records', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const records = await response.json();
                const currentDate = new Date();

                // Get the first day of the current month
                const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

                // Get the last day of the current month
                const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

                this.setState({ wasteRecords: records, startDate: firstDay.toISOString().split('T')[0], endDate: lastDay.toISOString().split('T')[0] }, () => {
                    // Call handleDateChange after state has been updated
                    this.handleDateChange({ target: { name: 'startDate', value: this.state.startDate } });
                    this.handleDateChange({ target: { name: 'endDate', value: this.state.endDate } });
                });
            } else {
                console.error('Failed to fetch records');
            }
        } catch (error) {
            console.error('Error fetching records:', error);
        }
    };
    handleinvreturn = () => {
        this.setState({ navigateinvreturn: true });
    };

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleDateChange = async (e) => {
        // Update state with the new value
        this.setState({ [e.target.name]: e.target.value }, async () => {
            const { startDate, endDate } = this.state;

            // Check if startDate and endDate are provided
            if (startDate && endDate) {
                try {
                    const token = localStorage.getItem('token');
                    const query = new URLSearchParams({
                        startDate: startDate,
                        endDate: endDate
                    }).toString();

                    const response = await fetch(`/foodwaste/records?${query}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const records = await response.json();
                        // Handle items here, e.g., set them in state
                        const sortedrecords = records.sort((a, b) => new Date(a.date) - new Date(b.date));

                        // Handle sorted items here, e.g., set them in state
                        this.setState({ wasteRecords: sortedrecords }); // Example
                    } else {
                        const errorText = await response.text();
                        this.setState({ error: errorText || 'Failed to fetch items' });
                    }
                } catch (error) {
                    console.error('Error:', error);
                    this.setState({ error: 'An unexpected error occurred' });
                }
            } else {
                console.error('Start Date and End Date must be provided.');
                this.setState({ error: 'Start Date and End Date must be provided.' });
            }
        });
    };

    handleWasteSubmit = async (e) => {
        e.preventDefault();
        const { wasteItemName, wasteQuantity, wasteReason, wasteEmployee, wasteManager, wasteDate, editingRecord } = this.state;
        const token = localStorage.getItem('token');

        const wasteData = { wasteItemName, wasteQuantity, wasteReason, wasteEmployee, wasteManager, wasteDate };

        try {
            let response;
            if (editingRecord) {
                // Update existing record
                response = await fetch(`/foodwaste/update/${editingRecord.id}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(wasteData),
                });
            } else {
                // Add new record
                response = await fetch(`/foodwaste/add`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(wasteData),
                });
            }

            if (response.ok) {
                const updatedRecord = await response.json();
                if (editingRecord) {
                    // Update existing record in the state
                    this.setState((prevState) => ({
                        wasteRecords: prevState.wasteRecords.map(record =>
                            record.id === updatedRecord.id ? updatedRecord : record
                        ),
                        wasteItemName: '',
                        wasteQuantity: 0,
                        wasteReason: '',
                        wasteEmployee: '',
                        wasteManager: '',
                        wasteDate: '',
                        editingRecord: null, // Reset editing state
                    }));
                } else {
                    // Add new record to the state
                    this.setState((prevState) => ({
                        wasteRecords: [...prevState.wasteRecords, updatedRecord],
                        wasteItemName: '',
                        wasteQuantity: 0,
                        wasteReason: '',
                        wasteEmployee: '',
                        wasteManager: '',
                        wasteDate: '',
                    }));
                }
            } else {
                console.error('Failed to save waste record');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    handleSearchChange = (e) => {
        this.setState({ searchQuery: e.target.value });
    };
    handleEdit = (record) => {
        this.setState({
            wasteItemName: record.wasteItemName,
            wasteQuantity: record.wasteQuantity,
            wasteReason: record.wasteReason,
            wasteEmployee: record.wasteEmployee,
            wasteManager: record.wasteManager,
            wasteDate: record.wasteDate,
            editingRecord: record, // Set the record being edited
        });
    };

    render() {
        const { wasteItemName, wasteQuantity, wasteReason, wasteEmployee, wasteManager, wasteDate, navigateinvreturn, wasteRecords, editingRecord } = this.state;
        if (navigateinvreturn) {
            return <Navigate to="/inventorysystem" />;
        }

        return (
            <div className="food-waste-page">
                <h1>Food Waste Tracker</h1>
                <p>Please use unique items name</p>
                <button onClick={this.handleinvreturn}>Return</button>
                <form onSubmit={this.handleWasteSubmit}>

                    <div className="form-group">
                        <label htmlFor="wasteItemName">Item Name:</label>
                        <input
                            type="text"
                            id="wasteItemName"
                            name="wasteItemName"
                            value={wasteItemName}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="wasteQuantity">Quantity:</label>
                        <input
                            type="text"
                            id="wasteQuantity"
                            name="wasteQuantity"
                            value={wasteQuantity}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="wasteReason">Reason for Waste:</label>
                        <input
                            type="text"
                            id="wasteReason"
                            name="wasteReason"
                            value={wasteReason}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="wasteEmployee">Employee:</label>
                        <input
                            type="text"
                            id="wasteEmployee"
                            name="wasteEmployee"
                            value={wasteEmployee}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="wasteManager">Manager:</label>
                        <input
                            type="text"
                            id="wasteManager"
                            name="wasteManager"
                            value={wasteManager}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="wasteDate">Date: Use Today Date</label>
                        <input
                            type="date"
                            id="wasteDate"
                            name="wasteDate"
                            value={wasteDate}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <button type="submit">
                        {editingRecord ? 'Update Waste Record' : 'Add Waste Record'}
                    </button>
                </form>
                <div className="form-group">
                    <label htmlFor="startDate">Start Date:</label>
                    <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={this.state.startDate}
                        onChange={this.handleDateChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="endDate">End Date:</label>
                    <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={this.state.endDate}
                        onChange={this.handleDateChange}
                    />
                </div>
                <h2>Waste Records</h2>
                <div className="card-container">
                    {wasteRecords.length > 0 ? (
                        wasteRecords.map((record) => (
                            <div key={record.id} className="card">
                                <h3>{record.wasteItemName}</h3>
                                <p><strong>Quantity:</strong> {record.wasteQuantity}</p>
                                <p><strong>Reason:</strong> {record.wasteReason}</p>
                                <p><strong>Employee:</strong> {record.wasteEmployee}</p>
                                <p><strong>Manager:</strong> {record.wasteManager}</p>
                                <p><strong>Date:</strong> {record.wasteDate}</p>
                                
                            </div>
                        ))
                    ) : (
                        <p>No waste records found.</p>
                    )}
                </div>
            </div>
        );
    }
}