import React, { Component} from 'react';
import { Navigate } from 'react-router-dom';

import './SubscriptionPage.css';



export class SubscriptionPage extends Component {
    static displayName = SubscriptionPage.name;

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            plan: 'basic',
            error: '',
            isSubscribed: false,
            token: '',
            isButtonClickable: false // State to manage button clickability
        };

        
    }

    componentDidMount() {
        this.fetchUserDetails();
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/buy-button.js'; 
        
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            console.log('Stripe script loaded successfully.');
        };

        script.onerror = () => {
            console.error('Failed to load Stripe script.');
        };
    
    }
    componentDidUpdate(prevProps, prevState) {
        // Check if password state has changed and now has a value
        if (this.state.password !== prevState.password && this.state.password) {
            this.handleStripeRedirect();
        }
    }
    fetchUserDetails = async () => {
        const token = localStorage.getItem('token');

        if (!token) {
            this.setState({ error: 'You must be logged in to subscribe.', isSubscribed: false });
            return;
        }

        try {
            const userResponse = await fetch('/user/userdetails', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (userResponse.ok) {
                const user = await userResponse.json();
                this.setState({ email: user.email });
            } else {
                const errorText = await userResponse.text();
                this.setState({ error: errorText || 'Failed to fetch user details' });
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
            this.setState({ error: 'An unexpected error occurred: ' + error.message });
        }
    };

    handleSubmitAndLogin = async (e) => {
        e.preventDefault();
        const { email, password, plan } = this.state;

        try {
            const loginResponse = await fetch('/account/createlogin', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password })
            });

            if (!loginResponse.ok) {
                const loginResponseText = await loginResponse.text();
                this.setState({ error: loginResponseText || 'Invalid credentials' });
                return;
            }

            const loginResult = await loginResponse.json();
            const { token } = loginResult;

            if (token) {
                localStorage.setItem('auth', true);
                localStorage.setItem('token', token);
                this.setState({ isAuthenticated: true });

                

                const subscriptionResponse = await fetch('/user/subscriptioncreate', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email, plan })
                });

                if (!subscriptionResponse.ok) {
                    const errorText = await subscriptionResponse.text();
                    this.setState({ error: errorText || 'Failed to create subscription' });
                    return;
                }

                const subscriptionResult = await subscriptionResponse.json();

                if (subscriptionResult.plan != null) {
                    this.setState({ isSubscribed: true });
                } else {
                    this.setState({ error: 'No plan set received from subscription creation' });
                }
            } else {
                this.setState({ error: 'No token received from login' });
            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred: ' + error.message });
        }
    };
   
    handleStripeRedirect = () => {
        // Disable the button and then enable it after 2 minutes
        this.setState({ isButtonClickable: false });
        setTimeout(() => {
            this.setState({ isButtonClickable: true });
        }, 120000); // 2 120000 minutes (120,000 milliseconds)
    };
    
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, error: '' });
    };

    render() {
        const { plan, error, isSubscribed, isButtonClickable } = this.state;

        if (isSubscribed) {
            return <Navigate to="/inventorysystem" />;
        }

        return (
           
            <div className="subscription-container">

                <h2 className="subscription-title">Subscribe Now</h2>
                {error && <p className="subscription-error">{error}</p>}
                <form className="subscription-form" onSubmit={this.handleSubmitAndLogin}>
                    <div className="form-group">
                        <label htmlFor="email">Reconfirm Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={this.state.email}
                            readOnly
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Reconfirm Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="plan">Select Subscription Plan:</label>
                        <select
                            id="plan"
                            name="plan"
                            value={plan}
                            onChange={this.handleChange}
                            className="form-control"
                        >
                            <option value="basic">Basic ($24.99)</option>
                            <option value="premium">Premium ($49.99)</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="card">Credit Card Information:</label>
                        <p>Pay through Stripe to enable the subscribe button.</p>
                        <p>If button does not redirect your to payment whitelist this site on adblocker </p>
                        <stripe-buy-button  buy-button-id="buy_btn_1Q7MI5P0eiQpiKfaRViZ3q8Y" publishable-key="pk_live_51Q1ektP0eiQpiKfaenkLYXj1PAhC5BDc55xIA2IXRdKXCGso5a2YewsfflSy3xnjbKZOtTN4g7xk0x4lizJK0S4600ZLuzKRbw">  </stripe-buy-button><stripe-buy-button  buy-button-id="buy_btn_1Q7MTMP0eiQpiKfaM0vIvhJW"publishable-key="pk_live_51Q1ektP0eiQpiKfaenkLYXj1PAhC5BDc55xIA2IXRdKXCGso5a2YewsfflSy3xnjbKZOtTN4g7xk0x4lizJK0S4600ZLuzKRbw"></stripe-buy-button>
                    </div>
                    <button
                        type="submit"
                        disabled={!isButtonClickable}
                        className={`subscription-button ${isButtonClickable ? 'enabled' : 'disabled'}`}
                    > Subscribe
                    </button>
                </form>
            </div>
        );
    }
}

